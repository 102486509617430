@import "styles/variables.scss";
.footer {
  position: relative;
  text-align: center;
  width: 100%;
  padding: 1rem 2rem;
  border-top: 2px solid var(--color-neutral-light);
  z-index: 3;
  background-color: var(--footer-base-color);

  &.noCopyright {
    padding: 3rem 2rem;
  }
}

.innerFooter {
  width: 100%;
  max-width: $max-page-width;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $footer-fill-color;

  &.hasClientInfo {
    align-items: flex-start;
    text-align: left;
    gap: 2rem;
    width: $max-page-width;
  }
}

.legalText {
  font-size: $font-size-small;

  a {
    display: inline-block;
    vertical-align: middle;
    color: $footer-fill-color;
    padding-bottom: 0;
    margin: 0 0.5rem;
    white-space: nowrap;

    &:focus {
      border: none;
      outline-color: $footer-fill-color;
    }
  }
}

.legalLinks {
  margin-bottom: 0.5rem;
}

.legalLinks .legalLink svg {
  fill: $footer-fill-color;
}

.logoWrapper,
.logoWrapperTop {
  width: 160px;
  display: block;
  padding-left: 1.5rem;
  margin-left: 1.5rem;
  border-left: 1px solid $footer-fill-color;

  svg {
    fill: $footer-fill-color;
  }

  &:global(.print-only) {
    display: none;
  }
}

.logoWrapperTop {
  display: none;
}

.logoLink {
  display: inline-block;
  border: none;

  &:focus {
    outline: $focus-indicator-contrast;
    outline-offset: -4px;
  }
}

.copyright {
  font-size: $font-size-small;
  line-height: $line-height-small;
  max-width: 460px;
}

.clientInfo {
  width: 40%;
}

.clientInfoLogo {
  max-width: var(--max-client-logo-width);
  max-height: var(--max-client-logo-height);
  margin: 0.25rem 0 0.5rem;

  img {
    width: auto;
    height: auto;
    max-height: var(--max-client-logo-height);
    max-width: min(100%, var(--max-client-logo-width));
  }
}

.clientInfoItem {
  margin-bottom: 0.25rem;
  a {
    color: var(--footer-fill-color);
  }
  svg {
    fill: var(--footer-fill-color);
  }
}

.hasClientInfo {
  .logoWrapperTop {
    display: block;
    border: none;
    margin: 0;
    width: 150px;
    padding: 0;
    &:global(.print-only) {
      display: none;
    }
  }
  .logoWrapper {
    display: none;
  }
  .legalText {
    width: 50%;
    border-left: 1px solid var(--footer-fill-color);
    padding-left: 1rem;
  }
  .copyright {
    max-width: none;
    padding-left: 0.25rem;
  }
}

@media screen and (max-width: $max-page-width) {
  .innerFooter.hasClientInfo {
    width: 100%;
    padding-bottom: 4rem;
  }
}

@media screen and (max-width: 830px) {
  .innerFooter {
    &.hasClientInfo {
      display: block;
      text-align: center;

      .clientInfo {
        width: 100%;
        padding-bottom: 0.5rem;
        border-bottom: 1px solid var(--footer-fill-color);
        margin-bottom: 0.5rem;
        text-align: start;
      }

      .legalText {
        width: 100%;
        border-left: none;
        padding-left: 0;
        text-align: start;
      }

      // Put back in if designer really wants to move the logo to the bottom
      /* .logoWrapperTop {
        display: none;
      }

      .logoWrapper {
        display: inline-block;
        border: none;
        text-align: center;

        &:global(.print-only) {
          display: none;
        }
      } */
    }
  }
}

@media screen and (max-width: 700px) {
  .innerFooter {
    display: block;
    text-align: center;
  }

  .legalText {
    padding: 0;
    width: auto;
    font-size: $font-size-small;
  }
  .copyright {
    max-width: none;
  }
  .logoWrapper {
    margin: 0;
    border: none;
    display: block;
    text-align: center;
    margin-top: 1rem;
    width: 100%;

    a {
      display: inline-block;
      width: 150px;
      border-bottom: none;
    }
  }
}

@media screen and (max-width: 600px) {
  .footer {
    padding-bottom: 5rem;
  }
}

@media print {
  .footer {
    width: 8.5in;
    align-items: flex-start;
    background-color: transparent;
    color: var(--color-foreground);
    padding: 0 2rem;
    border: none;
    box-shadow: none;
    max-width: $max-page-width;
  }

  .legalLinks {
    display: none;
  }

  .legalText {
    text-align: start;
  }

  .copyright {
    max-width: none;
  }

  .logoWrapper {
    margin: 0;
    border: none;
    width: 320px;
    &:global(.no-print) {
      display: none !important;
    }

    &:global(.print-only) {
      display: block;
    }
  }

  .logoWrapperTop {
    &:global(.print-only) {
      display: none !important;
    }
    &:global(.no-print) {
      display: none;
    }
  }

  .innerFooter {
    &.hasClientInfo {
      .logoWrapper {
        display: none;
        &:global(.no-print) {
          display: none;
        }
        &:global(.print-only) {
          display: none !important;
        }
      }
      .logoWrapperTop {
        display: none;
        &:global(.no-print) {
          display: none;
        }
        &:global(.print-only) {
          display: block !important;
        }
      }
    }
  }

  .logoLink {
    margin: 0;
    padding: 0;
  }
}
