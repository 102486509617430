@import "styles/variables.scss";
.logoWrapper {
  width: 100%;
  box-sizing: border-box;
}

.poweredByText {
  display: block;
  width: 100%;
  margin-bottom: 0.5rem;
}

.svgText {
  font-weight: 600;
  fill: var(--color-foreground);
  font-family: $font-family-primary;
  letter-spacing: 1.8px;
  font-size: 24px;
  text-anchor: middle;
}

.printSvgText {
  fill: black;
}

.lightSvgText {
  fill: var(--color-background);
}

.footerSvgText {
  fill: var(--footer-fill-color);
}

.headerSvgText {
  fill: var(--header-fill-color);
}

.subheaderSvgText {
  fill: var(--subheader-fill-color);
}

.startSvgText {
  text-anchor: start;
}

.endSvgText {
  text-anchor: end;
}

.logo {
  display: block;
  text-align: center;
  max-width: 100%;
}

.logoImage {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  transition: all $animation-time;

  & :global('.hw-default-logo') {
    display: block;
  }
}

a:hover .logoImage {
  border-radius: 8px;
  background-color: var(--color-primary-tr20);
}

a:hover .lightLogoImage {
  background-color: var(--color-neutral-minus);
}

@media (forced-colors: active) {
  .svgText {
    fill: $color-forced-light;
  }
}

@media (forced-colors: active) and (prefers-color-scheme: light) {
  .svgText {
    fill: $color-forced-dark;
  }
}
