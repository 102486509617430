@import "styles/variables.scss";
.app {
  width: 100%;
  box-sizing: border-box;
}

.app * {
  box-sizing: border-box;
}

.app_main {
  width: 100%;
  max-width: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2rem;
  background-color: var(--color-background);
  color: var(--color-foreground);
  box-shadow: 2px 2px 31px 0 rgba(0, 0, 0, 0.68);
}
