@import "styles/variables.scss";
.error {
  text-align: start;
  margin: 0 0 1rem;
}

.choiceWrapper {
  border: solid 1px var(--color-neutral-light);
  border-radius: 6px;
}

.comChoice {
  display: table;
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  padding: 0.5rem;
  background-color: var(--color-background);
  color: var(--color-foreground);
  border-radius: 6px;
  cursor: pointer;

  transition: background-color $animation-time;

  &:hover {
    background-color: var(--color-primary-tr20);
  }
}

.comChoiceBottom {
  border-top: solid 1px var(--color-neutral-light);
  padding-top: 0.5rem;
}

.radioWrapper,
.iconWrapper,
.comInfo {
  display: table-cell;
  vertical-align: middle;
  padding: 0;
  line-height: normal;
  text-align: start;
}

.radioWrapper {
  width: 44px;
  text-align: center;
}

.iconWrapper {
  width: 44px;
  height: 32px;

  svg {
    width: 32px;
    height: 32px;
  }
}

.comInfo {
  padding: 0.5rem 0.5rem 0.5rem 0;
}

.skipRedirectWrapper {
  margin: 1.5rem 0 2rem;
  min-height: 30px;

  .skipRedirectLink {
    font-size: $font-size-small;
    line-height: normal;
    letter-spacing: normal;
    display: inline-block;
    vertical-align: bottom;
    font-weight: 500;
  }
}

.comValue {
  font-weight: 500;
  display: block;
  word-break: break-all;
}

.comVoipWarning,
.comInstructions {
  margin-top: 0.3rem;
  display: block;
  font-size: $font-size-small;
  line-height: normal;
}

.comVoipWarning {
  color: var(--color-important);
  font-style: italic;
  font-weight: 700;
}

.buttonWrapper {
  margin-top: 1rem;
}

.waitButton {
  width: 100%;
  max-width: 500px;
}

.communicationWarning {
  margin: 1rem 0;
  padding: 1rem;
  border: solid 2px var(--color-accent-tr40);
  border-radius: 4px;
  font-size: $font-size-normal;
  line-height: $line-height-normal;
  color: var(--color-important);
}

.loginTitle {
  font-size: $font-size-large;
  line-height: $line-height-large;
  letter-spacing: normal;
  margin: 0.5rem 0 0;
}

.loginOtherInfo {
  font-size: $font-size-normal;
  font-weight: 400;
  line-height: $line-height-normal;
  margin: 0.5rem 0 1rem;
  letter-spacing: normal;
}

.comQuestion {
  border: 0;
  padding: 0.01em 0 0 0;
  margin: 0;
  min-width: 0;

  legend {
    padding: 0;
    display: table;
  }

  .choiceWrapperPlaceholder {
    height: 200px;
    min-height: 200px;
  }
}

.postLogoutMessageIcon {
  text-align: center;
  margin: 1rem 0;

  svg {
    display: inline-block;
    width: 100px;
  }
}

.moreInfoBtn {
  display: inline-block;
  vertical-align: top;
  margin-left: 0.25rem;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border: none;
  background: none;
  padding: 0;
  text-align: start;
  border-radius: 50%;

  &:hover,
  &:focus,
  &.active {
    outline: none;
  }
}

.moreInfoBtnInner {
  display: inline-block;
  vertical-align: top;
  border: solid 1px var(--color-primary);
  background-color: var(--color-background);
  color: var(--color-primary);
  text-align: center;
  font-weight: 700;
  font-size: 10px;
  padding: 1px 1px 0 0;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  transition:
    color $animation-time,
    background-color $animation-time;
}

.moreInfoBtn {
  &:hover,
  &:focus,
  &.active {
    .moreInfoBtnInner {
      color: var(--link-btn-fill-color);
      background-color: var(--link-btn-base-color);
      outline: $focus-indicator;
      outline-offset: $focus-indicator-offset;
    }
  }
}

.moreInfoMessage {
  display: none;

  &.open {
    display: block;
    margin: 1rem auto 0;
    position: relative;
    font-family: sans-serif;
    font-size: $font-size-small;
    line-height: $line-height-small;
    width: 300px;
    border-radius: 15px;
    padding: 8px;
    text-align: center;
  }

  &:focus {
    outline: $focus-indicator;
    outline-offset: $focus-indicator-offset;
  }
}

@media screen and (max-width: 400px) {
  .moreInfoMessage {
    &.open {
      width: 250px;
    }
    &.moreInfoMessageTopRight:before {
      right: 85px;
    }
  }
}

@media (forced-colors: active) {
  .moreInfoMessage.moreInfoMessageTopRight:before {
    display: none;
  }
}
