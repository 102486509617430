@import "styles/variables.scss";
.scrollIconBox {
  display: inline-block;
  padding: 0.5rem;
  background-color: var(--link-btn-base-color-tr80);
  border: solid 1px var(--color-neutral-tr70);
  border-radius: 8px;
  text-decoration: none;
  &:hover,
  &:focus {
    border-color: var(--color-foreground);
    background-color: var(--link-btn-base-color);
    text-decoration: underline;
    svg {
      fill: var(--link-btn-fill-color);
    }
  }

  &:focus {
    outline: $focus-indicator-medium;
  }
}

.scrollIconBox.animate {
  transition: all $animation-time;
  svg {
    transition: all $animation-time;
  }
}

.scrollIcon {
  display: block;
  margin: 0 auto;
  width: 36px;
  height: auto;

  svg {
    fill: var(--link-btn-fill-color);
  }
}

.scrollIconText {
  font-size: $font-size-small;
}
