@import "styles/variables.scss";
.pageWrapper {
  width: 100%;
  min-width: 300px;
  min-height: 100vh;
}

.skipContentLink {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  background-color: var(--color-background);
  color: var(--color-primary);

  &:focus {
    width: auto;
    height: auto;
    top: 0;
    left: 0;
    padding: 0 4px;
    border: none;
    z-index: 100;
  }
}

.pageWrapperContent {
  width: 100%;
}

.scrollTopIconWrapper {
  display: block;
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  z-index: 9;
  visibility: visible;
  opacity: 1;
  &.animate {
    transition:
      visibility $animation-time,
      opacity $animation-time;
  }
}

.scrollTopIconWrapperHidden {
  visibility: hidden;
  opacity: 0;
}
