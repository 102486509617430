.logoImage {
  display: inline-block;
  width: 100%;
}

.logoImage svg {
  width: 100%;
}

.hideMeatball {
  transform: translateY(-1.5rem);
}
