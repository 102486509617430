@import "styles/variables.scss";
.modal_wrapper {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  background: rgba(16, 24, 32, 0.95);
  overflow-y: auto;
  z-index: 10;
  text-align: center;
}

.modal_wrapper_light {
  background: rgba(255, 255, 255, 0.9);
}

.modal_wrapper_medium_light {
  background: rgba(255, 255, 255, 0.7);
}

.modal_wrapper_medium_dark {
  background: rgba(16, 24, 32, 0.5);
}

.modal_wrapper_dark {
  background: rgba(16, 24, 32, 0.95);
}

.modal_wrapper * {
  box-sizing: border-box;
}

.modal_content_wrapper {
  display: block;
  height: 100%;
}

.modal_close {
  position: absolute;
  top: 8px;
  right: 16px;
  padding: 8px;
  background: transparent;
  border: 2px dotted transparent;
  cursor: pointer;
}

.modal_close svg {
  width: 20px;
  height: 20px;
  fill: white;
}

.modal_close:focus {
  outline: none;
  border-color: white;
}

.modal_wrapper_light .modal_close svg,
.modal_wrapper_medium_light .modal_close svg {
  width: 32px;
  fill: var(--color-neutral-x-dark);
}

.modal_wrapper_light .modal_close:focus,
.modal_wrapper_medium_light .modal_close:focus {
  outline: none;
  border-color: var(--color-neutral-x-dark);
}

@media (forced-colors: active) {
  .modal_wrapper {
    background-color: $color-forced-dark;
  }
}

@media (forced-colors: active) and (prefers-color-scheme: light) {
  .modal_wrapper {
    background-color: $color-forced-light;
  }
}
