@import "styles/variables.scss";
$smallIconWidth: 16px;
$mediumIconWidth: 18px;
$normalIconWidth: 24px;
$largeIconWidth: 32px;

.iconLabelWrapper {
  display: inline-flex;
  align-items: flex-start;
}

.iconWrapper {
  display: inline-block;
  vertical-align: top;
  width: $normalIconWidth;
  height: $normalIconWidth;

  svg {
    width: $normalIconWidth;
    height: $normalIconWidth;
  }
}

.iconLabel {
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.5rem;
  font-size: $font-size-normal;
}

.iconLabelWrapper.small {
  .iconWrapper {
    width: $smallIconWidth;
    height: $smallIconWidth;

    svg {
      width: $smallIconWidth;
      height: $smallIconWidth;
    }
  }

  .iconLabel {
    margin-left: 0.25rem;
    font-size: $font-size-small;
  }
}

.iconLabelWrapper.medium {
  .iconWrapper {
    width: $mediumIconWidth;
    height: $mediumIconWidth;

    svg {
      width: $mediumIconWidth;
      height: $mediumIconWidth;
    }
  }

  .iconLabel {
    margin-left: 0.3rem;
    font-size: $font-size-small-plus;
  }
}

.iconLabelWrapper.large {
  .iconWrapper {
    width: $largeIconWidth;
    height: $largeIconWidth;

    svg {
      width: $largeIconWidth;
      height: $largeIconWidth;
    }
  }

  .iconLabel {
    margin-left: 0.75rem;
    font-size: $font-size-large;
  }
}
