@import "styles/variables.scss";
.externalLink {
  min-height: 24px;
  text-decoration: none;
}

.externalLinkText {
  text-decoration: underline;
}

.externalLinkIconWrapper {
  width: 16px;
  height: 16px;

  svg {
    width: 16px;
    height: 16px;
    fill: var(--color-neutral-dark);
  }
}
