@import "styles/variables.scss";
.hwCookieBanner {
  background-color: var(--header-base-color);
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  font-size: $font-size-small;
  line-height: $line-height-small;
}

.hwCookieBanner:focus {
  outline: $focus-indicator-contrast;
  outline-offset: -2px;
}

.hwCookieBanner.hidden {
  display: none;
}

.hwCookieBannerOuter {
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
}

.hwCookieBannerInner {
  position: relative;
  padding: 0.75em 5em 0.75em 2.5em;
  color: var(--header-fill-color);
}

.hwCookieBannerInner a {
  color: var(--header-fill-color);
}

.hwCookieBannerText {
  text-align: start;
}

.hwCookieBannerLink {
  text-decoration: underline;
  svg {
    fill: var(--header-fill-color);
  }
}

.hwCookieBannerClose {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background: transparent;
  border: none;
  color: var(--header-fill-color);
  cursor: pointer;
  width: 26px;
  height: 26px;
  padding: 3px;

  svg {
    width: 20px;
    height: 20px;
    fill: var(--header-fill-color);
  }
}

.hwCookieBannerInner a:focus,
.hwCookieBannerClose:focus {
  outline: $focus-indicator-contrast;
}

@media screen and (max-width: 400px) {
  .hwCookieBannerClose {
    top: 1rem;
    right: 1rem;
    width: 24px;
    height: 24px;
    padding: 4px;

    svg {
      width: 16px;
      height: 16px;
    }
  }
}
