@import "styles/variables.scss";
.logoImage {
  display: inline-block;
  width: 100%;
}

.logoImage svg {
  width: 100%;
}

.hideMeatball {
  transform: translateY(-1.5rem);
}

.logo {
  fill: var(--bac);
}

.logo_print {
  fill: black;
}

.logo_dark {
  fill: var(--color-foreground);
}

.logo_footer {
  fill: $footer-fill-color;
}

.logo_header {
  fill: var(--header-fill-color);
}

.logo_subheader {
  fill: var(--sub-header-fill-color);
}

@media (forced-colors: active) {
  .logo_dark {
    fill: $color-forced-light;
  }
}

@media (forced-colors: active) and (prefers-color-scheme: light) {
  .logo_dark {
    fill: $color-forced-dark;
  }
}
